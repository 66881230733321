<app-cms-header></app-cms-header>
<div class="modal-content screen-height">
    <div class="modal-body">
        <div class="verify text-center">
            <img src="assets/cms-image/approve.png" height=80px class="m-3" alt="">
            <h4>Your account has been successfully activated!</h4>
            <p >Thank you for choosing our application. We look forward to serving you.</p>
            <p >Best regards</p>
        </div>
    </div>
</div>
<app-footer></app-footer>
