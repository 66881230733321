export const env = {
    apiBaseUrl: 'https://ish-gateway.signitydemo.in/api/',
    // apiBaseUrl: 'http://10.5.1.69:5075/api/',
    // apiBaseUrl: 'http://10.5.1.36:5065/api/',
    imageUrl: 'https://d16xdy485vjfhf.cloudfront.net/',
    // firebaseConfig: {
    //   apiKey: "AIzaSyC3qi9FzxNeQf43fhi8GmNPKBTPOcKSJw4",
    //   authDomain: "rhpuat.firebaseapp.com",
    //   projectId: "rhpuat",
    //   storageBucket: "rhpuat.appspot.com",
    //   messagingSenderId: "675300811932",
    //   appId: "1:675300811932:web:5f3b7ceade89f0dfd67959",
    //   measurementId: "G-SRP39MEY72"
    // },
  }