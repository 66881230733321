import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TitleStrategy } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageTitleService, PageLoaderComponent } from './shared/public-api';
import { HttpClientModule } from '@angular/common/http';
import { I18nModule } from './i18n/i18n.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsComponent } from './shared/components/cms/cms.component';
import { TermsComponent } from './shared/components/cms/terms/terms.component';
import { PrivacyComponent } from './shared/components/cms/privacy/privacy.component';
import { VerifyEmailComponent } from './shared/components/verify-email/verify-email.component';
import { AddMoreComponent } from './modals/add-more/add-more.component';
import { SupportComponent } from './shared/components/cms/support/support.component';
import { FaqComponent } from './shared/components/cms/faq/faq.component';
import { AboutUsComponent } from './shared/components/cms/about-us/about-us.component';
import { CountryFlagDirective } from './shared/directives/country-flag.directive';
import { NoPermissionsFoundComponent } from './shared/components/no-permissions-found/no-permissions-found.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReportReasonComponent } from './modals/report-reason/report-reason/report-reason.component';
import { CmsHeaderComponent } from "./shared/components/cms/cms-header/cms-header.component";
import { FooterComponent } from "./shared/components/footer/footer.component";
import { DeleteAccountComponent } from './shared/components/cms/delete-account/delete-account.component';
import { PaymentStatusPageComponent } from './shared/components/cms/payment-status-page/payment-status-page.component';
import { StripePaymentComponent } from './shared/components/cms/stripe-payment/stripe-payment.component';
import { RefreshPageStripeComponent } from './shared/components/cms/refresh-page-stripe/refresh-page-stripe.component';
import { SafeHtmlPipe } from './shared/pipes/safe.pipe';


@NgModule({
    declarations: [AppComponent, PageLoaderComponent, CmsComponent, TermsComponent, PrivacyComponent, VerifyEmailComponent, AddMoreComponent, SupportComponent, FaqComponent, AboutUsComponent, CountryFlagDirective, NoPermissionsFoundComponent, ReportReasonComponent, DeleteAccountComponent,PaymentStatusPageComponent,StripePaymentComponent,RefreshPageStripeComponent,SafeHtmlPipe],
    providers: [
        {
            provide: TitleStrategy,
            useClass: PageTitleService,
        },
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        HttpClientModule,
        BrowserAnimationsModule,
        I18nModule,
        NgbModule,
        HttpClientModule,
        TranslateModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        CmsHeaderComponent,
        FooterComponent
    ]
})
export class AppModule {}
