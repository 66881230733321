import { Component, Input } from '@angular/core';
import { DriverAboutUs, PassengerAboutUs } from '../static-data/about-us-driver';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  @Input() content: string = '';
  @Input() contentObject: any;

  innerContent = DriverAboutUs
  innerContentPassenger= PassengerAboutUs
}
