import { Component, Input } from '@angular/core';
import { DriverTermsAndCondition, passengerTermsAndCondition } from '../static-data/terms-and-condition-driver';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  @Input() content: string = ''
  @Input() contentObject: any;
  innerContent = DriverTermsAndCondition
  innerContentForPassenger = passengerTermsAndCondition
}
