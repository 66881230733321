import { Component, Input } from '@angular/core';
import { DriverSupport } from '../static-data/support-driver';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent {
  @Input() content: string = ''
  @Input() contentObject: any;

  innerContent= DriverSupport
}
