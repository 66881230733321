import { Component, Input } from '@angular/core';
import { DriverFaqs } from '../static-data/faqs-driver';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  @Input() content: string = ''
  @Input() contentObject: any;
  innerContent = DriverFaqs
}
