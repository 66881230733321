<div class="container">
  <div class="row">
    <div class="col-md-10 m-auto">
      <h3 class="mb-3 text-center">Privacy Policy</h3>
      <div [innerHTML]="(contentObject =='driver' ? innerContentForDriver : innerContentForPassenger) | safeHtml"></div>
    </div>
  </div>
</div>




