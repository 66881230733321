import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageType } from 'src/app/feature/post-auth/modules/terms-pages/enums/page-type.enums';
import { TermsPagesService } from 'src/app/feature/post-auth/modules/terms-pages/services/terms-pages.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {

  screenType = PageType.PRIVACY_POLICY;
  userType : string = ''
  contentObject : any ;
  data: string = ''
  private subscription: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,public route : Router,
    private termsService: TermsPagesService,
  ) { }

  ngOnInit(): void {
    if(this.route.url.includes('driver')){
      this.userType= 'driver';
    }else{
      this.userType = 'passenger'
    }
    this.getParams()
    
  }

  private getParams(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.type) {
        this.screenType = params['type'];
        if(this.screenType !== 'deleteAccount'){
          // this.getData(this.screenType)
        }
      }
    })
  }

  private getData(type: string): void {
    this.subscription.add(
      this.termsService.httpGetPageDetail(type,this.userType).subscribe({
        next: (res: any) => {
          if (res && res.data.data) {
            this.contentObject = res.data.data
            this.data = res.data.data.description 
          }
        },
      }),
    );
  }

}
