import { Component, Input } from '@angular/core';
import { DriverPrivacyPolicy, PassengerPrivacyPolicy } from '../static-data/privacy-policy-driver';
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  @Input() content: string = ''
  @Input() contentObject: any;

  innerContentForDriver= DriverPrivacyPolicy
  innerContentForPassenger =PassengerPrivacyPolicy
}
